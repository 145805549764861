import {
    Create,
    Form,
    Input,
    useForm,
} from "@pankod/refine-antd";


export const UsersCreate = () => {

    const { formProps, saveButtonProps } = useForm();

    return (

        <Create saveButtonProps={ saveButtonProps } title="Agregar administrador">

            <Form { ...formProps } layout="horizontal">

                <Form.Item
                    label="Usuario (correo electronico)"
                    name="email"
                    rules={ [
                        { required: true }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nombres"
                    name="first_name"
                    rules={ [
                        { required: true }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Apellidos"
                    name="last_name"
                    rules={ [
                        { required: true }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={ [
                        { required: true }
                    ]}
                >
                    <Input
                        type="password"
                    />
                </Form.Item>

            </Form>

        </Create>

    )

}
