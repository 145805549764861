import axios from 'axios';

import { API_URL } from '../../config/settings';
import { requestLogin, validateToken } from '../../api/auth';

export const axiosInstance = axios.create();

export function checkAuth(){

    const token = localStorage.getItem('authToken');

    if(!token){

        return Promise.reject('/login');

    }

    axiosInstance.defaults.headers = {
        'x-access-token': token,
    }


    return Promise.resolve(true);

}


export function checkError () { return Promise.resolve(); };


export function getPermissions() {

    const token = localStorage.getItem('authToken');

    if(!token){

        return Promise.reject('/login');

    }

    return Promise.resolve(['Admin']);

}



export function getUserIdentity() {

    const userIdentity = localStorage.getItem('identity');

    if(userIdentity){

        const identity = JSON.parse(userIdentity);

        return Promise.resolve({ name: `${ identity.firstname } ${ identity.lastname }`, id_adxpace: identity.id_adxpace });
    }

    return Promise.reject();

}


const login = (params) => {

    return new Promise((resolve, reject) => {

        requestLogin({ username: params.username, password: params.password }, API_URL)
        .then(loginResponse => {

            const { token, email, firstname, lastname } = loginResponse.data;

            const identity = { firstname, lastname, email }

            localStorage.setItem("authToken", token);
            localStorage.setItem("identity", JSON.stringify(identity));

            axiosInstance.defaults.headers = {
                'x-access-token': token,
            }

            return resolve('/');

        })
        .catch(reject);

    });

}


export function logout() {

    localStorage.removeItem('authToken');
    localStorage.removeItem('identity');

    return Promise.resolve();

}


export { login };
