import {
    List,
    ShowButton,
    Space,
    Table,
    useTable,
} from "@pankod/refine-antd";



export const CalificacionesList = () => {

    const { tableProps, tableQueryResult } = useTable();

    console.log(tableQueryResult);


    return (

        <List createButtonProps={{ children: "Subir calificaciones", size: "large" }} title={ `Calificaciones` } pageHeaderProps={{ subTitle: `Listado de alumnos` }}>

            <Table { ...tableProps } rowKey="id">

                <Table.Column dataIndex="matricula" title="Matr&iacute;cula" />

                <Table.Column dataIndex="nombre" title="Nombre del alumno" />

                <Table.Column dataIndex="grupo" title="Grupo" />

                <Table.Column
            					title="Consulta de calificaciones"
            					dataIndex="actions"

            					render={ (_text, record) => (

            						<Space>

                                        <ShowButton
            								size="small"
            								recordItemId={ record.id }
            							>
                                            Calificaciones
                                        </ShowButton>

            						</Space>

            					)}
            				/>

            </Table>

        </List>

    )


}
