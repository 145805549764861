import { useApiUrl, useCreate, useNavigation } from "@pankod/refine-core";

import { AntdLayout, Checkbox, Create, Form, getValueFromEvent, Image, Input, InputNumber, message, Modal, Select, Typography, Upload, useForm, useSelect } from "@pankod/refine-antd";

import './styles.css';

const { Title, Text } = Typography;



export const CalificacionesCreate = () => {

    const { list } = useNavigation();

    const { queryResult } = useCreate();

    const apiUrl = useApiUrl();

    const { form, formProps, saveButtonProps } = useForm();


    return (



            <Form {  ...formProps }
                initialValues={{

                }}
                layout="vertical"
            >

                <Form.Item label="Archivo Excel">

                    <Form.Item

                        getValueFromEvent={ getValueFromEvent }
                        noStyle
                        name="file"
                        valuePropName="file"
                    >

                        <Upload.Dragger
                            action={ `${ apiUrl }/calificaciones.php` }
                            className="uploader"
                            headers={{ 'x-access-token': localStorage.getItem('authToken') }}
                            name="file"
                            onChange={
                                (info) => {

                                    const { status } = info.file;

                                    if (status === 'done') {

                                        message.success(`Archivo de calificaciones ${ info.file.name } subido y procesado correctamente.`, 7);

                                        list('calificaciones.php');

                                    } else if (status === 'error') {
                                        message.error(`Ocurrio un error subiendo el archivo ${info.file.name}.`);
                                    }

                                }

                            }
                        >

                            <Title level={ 4 }>
                                Arrastre archivo de calificaciones aqu&iacute;.
                            </Title>

                        </Upload.Dragger>


                    </Form.Item>

                </Form.Item>

            </Form>




    )



}
