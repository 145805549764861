import {
    DeleteButton,
    EditButton,
    List,
    Space,
    Table,
    useTable,
} from "@pankod/refine-antd";


export const UsersList = () => {


    const { tableProps } = useTable();



    return (

        <List title={ `Usuarios administradores` } >

            <Table { ...tableProps } rowKey="id">

                <Table.Column dataIndex="id" title="Id" />

                <Table.Column dataIndex="email" title="Nombres" />

                <Table.Column dataIndex="first_name" title="Nombres" />

                <Table.Column dataIndex="last_name" title="Apellidos" />

                <Table.Column
            					title="Consulta de calificaciones"
            					dataIndex="actions"

            					render={ (_text, record) => (

            						<Space>

                                        <EditButton
            								size="small"
            								recordItemId={ record.id }
            							>
                                            Editar
                                        </EditButton>

            						</Space>

            					)}
            				/>

            </Table>

        </List>


    )


}
