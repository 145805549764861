import {
    Edit,
    Form,
    Input,
    useForm,
} from "@pankod/refine-antd";


export const UsersEdit = () => {

    const { formProps, saveButtonProps } = useForm();


    return (

        <Edit saveButtonProps={ saveButtonProps  } title='Editar usuario administrador'>

            <Form { ...formProps } layout="horizontal">

                <Form.Item
                    label="Usuario (correo electronico)"
                    name="email"
                    rules={ [
                        { required: true }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nombres"
                    name="first_name"
                    rules={ [
                        { required: true }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Apellidos"
                    name="last_name"
                    rules={ [
                        { required: true }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={ [

                    ]}
                >
                    <Input
                        type="password"
                        placeholder="●●●●●●●●"
                    />
                </Form.Item>

            </Form>

        </Edit>

    )

}
