import { UserOutlined } from "@ant-design/icons";
import { Refine, AuthProvider } from "@pankod/refine-core";
import { Layout, notificationProvider, ErrorComponent } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";

import "@pankod/refine-antd/dist/styles.min.css";


import logo from '../../../assets/logo.jpeg';
import { API_URL } from '../../../config/settings';
import { axiosInstance, checkAuth, checkError, getPermissions, getUserIdentity, login, logout } from '../authProvider';
import { Login } from '../../login';

import { UsersCreate, UsersEdit, UsersList } from '../../adminUsers';
import { CalificacionesCreate, CalificacionesList, CalificacionesShow } from '../../calificaciones';

import "./styles.css";


const App: React.FC = () => {

    const authProvider: AuthProvider = {
        checkAuth: checkAuth,
        checkError: checkError,
        getPermissions: getPermissions,
        getUserIdentity: getUserIdentity,
        login: login,
        logout: logout
    }

    const CustomTitle = ({ collapsed } : { collapsed: any }) => (
        <div >
            { collapsed ? (
                <img
                    src={ logo }
                    alt="Liceo Morelia"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "90px",
                        padding: "12px 0",
                        width: "120px"
                    }}
                />
            ) : (
                <img
                    src={ logo }
                    alt="Liceo Morelia"
                    style={{
                        backgroundColor: "rgba(250, 250, 250, 0.77)",
                        padding: "12px 24px",
                        width: "200px",
                    }}
                />
            )}
        </div>
    );


    return (
        <Refine
            authProvider={ authProvider }
            catchAll={<ErrorComponent />}
            dataProvider={ dataProvider(API_URL, axiosInstance) }
            Layout={ Layout }
            LoginPage={ Login }
            notificationProvider={ notificationProvider }
            resources={ [
                {
                    name: 'admins.php',
                    create: UsersCreate,
                    edit: UsersEdit,
                    icon: <UserOutlined />,
                    list: UsersList,
                    options: { label: 'Administradores' }
                },
                {
                    name: 'calificaciones.php',
                    create: CalificacionesCreate,
                    list: CalificacionesList,
                    options: { label: 'Calificaciones' },
                    show: CalificacionesShow
                }
            ] }
            routerProvider={ routerProvider }
            Title={ CustomTitle }
        />
    );
};

export default App;
